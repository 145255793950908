* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
}

#app { background-color: #f9f9f9; min-height: 100vh; }


.o-wrapper {
	max-width: 100%;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;
    padding-bottom: 50px; 
}

.o-flex {
	display: flex;
	justify-content: space-between;
}
.o-flex > * {
	margin-right: 15px;
}
.o-flex > *:last-child {
	margin-right: none;
}
.o-flex--center {
	align-items: center;
}

.mb30 { margin-bottom: 30px; }


.wcn-header__link { text-decoration: none; color: white; }
.c-header__admin { background-color: #017eb4 !important; }
.c-header {
	background-color: #222;
	color: white;
	padding: 40px 0;
	margin-bottom: 40px;
}
.c-header h1 {
	margin-top: 0;
	text-align: center;
	font-weight: 400;
	letter-spacing: 4px;
	font-size: 42px;
}



.c-navigation {
	padding-bottom: 50px;
}
.c-navigation ul {
	list-style-type: none;
    text-align: center;
}
.c-navigation li {
	display: inline-block;
    margin: 0 8px;
}
.c-navigation a {
	font-size: 24px;
}


.c-form__error { color: red; padding: 10px 0px; display: flex; justify-content: center; }

.wcn-table__head { background-color: #fafafa; }
.c-table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}

.c-table thead th {
	color: #fff;
	background-color: #222;
	border-color: #32383e;
	vertical-align: bottom;
	border-bottom: 2px solid #dee2e6;
	font-weight: 400;
}

.c-table td,
.c-table th {
	padding: .75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
	font-size: 14px;
}






.c-form__form-group {
    margin-bottom: 1rem;
}
label {
	display: inline-block;
	margin-bottom: .5rem;
}
.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	min-height: 34px;
}







.c-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	color: #fff;
    background-color: #007bff;
	border-color: #007bff;
    font-size: 14px;
	cursor: pointer;
	margin-top: 5px; 
	margin-bottom: 5px;
}
.c-btn--warn {
	background-color: red;
	border-color: red;
}

.c-modal__bg {
	z-index: 1;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
    align-items: center;
	justify-content: center;
	overflow-y: scroll;
}
.c-modal {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
	outline: 0;
	max-width: 800px;
	opacity: 1;
	padding: 10px;
	max-height: 95%;
	overflow-y: scroll;
}
.c-modal__header {
	margin: -10px -10px 0 -10px;
    background-color: #222;
    padding: 10px;
	color: #fff;
	margin-bottom: 20px;
	max-width: inherit; 
}
.c-modal__header h2 {
	font-weight: 400;
}






.c-underline-row {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 30px;
	margin-top: 0;
}
.c-underline-row li  {
	border-bottom: 1px solid #dee2e6;
	padding: 12px 0;
}











.hidden {
	display: none;
}



/*********************** Spinner ********************/
.load-bar {position: fixed; top: 0; left:0; right:0; width: 100%; height: 6px; background-color: #1e9be9; z-index: 999; }
.bar { content: ""; display: inline; position: absolute; width: 0; height: 100%; left: 50%; text-align: center; }
.bar:nth-child(1) { background-color: #f7f7f7; animation: loading 3s linear infinite; }
.bar:nth-child(2) { background-color: #fdb21b; animation: loading 3s linear 1s infinite; }
.bar:nth-child(3) { background-color: #1e9be9; animation: loading 3s linear 2s infinite; }
@keyframes loading { from {left: 50%; width: 0;z-index:100;} 33.3333% {left: 0; width: 100%;z-index: 10;} to {left: 0; width: 100%;} }

/* .App {
	
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */


/**************
* Drawer
***************/
.MuiDrawer-paper { width: 1200px; max-width: 100%; }